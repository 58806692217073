import React from 'react';
import URLQuery from 'query-string-manipulator';
import autoBind from 'react-autobind';
import IDM from '@ikonintegration/idmclient';
import SMOrganization from '@ikonintegration/mod-organization-client';
import LogRocket from 'logrocket';
//
import Cache from './Cache';
import Utils from './Utils';
import API from './API/API';
//
import PaymentManager from './PaymentManager';
import AlertController from './AlertController';
//
import config from '../config/config';
import packageJSON from '../../../package.json';
import Globals from '../config/Globals';
//
const urlParse = require('url-parse');
//
export default class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    console.debug(`CCPO FE - version: ${packageJSON.version}@${process.env.COMMIT_HASH}`);
    //
    this.isAuthenticating = false;
    this._sharedCache = null; //will be set
    this.sessionWillLoadHandler = null;
    this.sessionDidLoadHandler = null;
    this.sessionDidFailLoadHandler = null;
    this.idm = new IDM(config.IDMClientTokenKey, config.IDMClientTokenSecret, config.IDMClientOptions);
    this.organization = new SMOrganization({
      endpoint: config.SMOrganizationEndpoint,
      authorizationToken: this._getIDMToken.bind(this),
      tenantID: config.SMOrganizationTenantID,
    });
    this.idm.sessionLoadedHandler = this.sessionLoadedHandler.bind(this);
    this.api = new API(this.idm);
    this.paymentManager = new PaymentManager(this);
  }
  async componentDidMount() {
    this.startLoading(true);
    if (!this.idm.isLogged()) await this.idm.load(); // ask for revalidation if have session - will redirect
    if (!this.idm.isLogged()) {
      await this.redirectToAuth();
      this.endLoading(true); //if we kept logged out, stop loading
    }
  }
  // Shortcuts on application levels
  isAdmin() {
    return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ADMIN') : false;
  }
  isUser() {
    return this.idm.session.authorization
      ? this.idm.session.authorization.hasClaim('USER') && !this.isAdmin() && !this.isAdvisor()
      : false;
  }
  isAdvisor() {
    return this.idm.session.authorization ? this.idm.session.authorization.hasClaim('ADVISOR') : false;
  }
  getAuthorizedUserID() {
    return this.idm.session.authorization.getUserID();
  }

  // Loading
  startLoading(reload) {
    this.isAuthenticating = true;
    if (reload) this.forceUpdate();
  }

  endLoading(reload) {
    this.isAuthenticating = false;
    if (reload) this.forceUpdate();
  }

  // Singleton
  sharedCache() {
    if (this._sharedCache == null) {
      this._sharedCache = new Cache(this);
    }
    return this._sharedCache;
  }

  // Authentication entrypoint
  async redirectToAuth() {
    // const isRegistering = this.props.history.location.pathname == config.ApplicationRoutes.register;
    // if (isRegistering)
    //   this.props.app.idm.registration.register([
    //     config.IDMClientOptions.roles.USER,
    //     config.IDMClientOptions.roles.SHARED_MODULES,
    //   ]);
    await this.idm.authenticator.login(
      null,
      null,
      [config.IDMClientOptions.roles.USER, config.IDMClientOptions.roles.SHARED_MODULES],
      window.location
    );
  }

  // IDM delegate calls
  async sessionLoadedHandler() {
    //Todo prevent double session load!
    if (this.isLoadingSession) return;
    this.isLoadingSession = true;
    if (this.sessionWillLoadHandler) this.sessionWillLoadHandler();
    //session will be loaded by this.idm.load or by login call, both should be
    //loading while this is called, so we dont update state when starting loading for session load
    //reloading state will cause authorized area to be called while we haven't fully loaded the session
    this.startLoading(false);
    //
    this._identifyUser();
    await this._loadCache();
    //End loading, unblock other session load
    this.isLoadingSession = false;
    this.endLoading(true);
  }

  // Navigation stack
  pushPage(pagePath, args, id, id2, replace) {
    // replace id - TODO: do it dynamically
    if (id2 != undefined && id2 != null) pagePath = pagePath.replace(`:${Globals.URL_Path_ID2_Placeholder}`, id2);
    if (id != undefined && id != null) pagePath = pagePath.replace(`:${Globals.URL_Path_ID_Placeholder}`, id);
    // build url
    const reqURL = URLQuery(pagePath, { set: args || {} });
    console.debug('Pushing page', reqURL);
    //push new page
    if (replace) this.props.history.replace(reqURL);
    else this.props.history.push(reqURL);
  }
  pushExternalPage(url) {
    const curr = urlParse(window.location.href);
    const changeURL = `${curr.protocol.replace(':', '')}://${url}`;
    console.log(changeURL);
    window.location.href = changeURL;
  }
  openExternalPage(url, appendProtocol) {
    if (appendProtocol) {
      const curr = urlParse(window.location.href);
      url = `${curr.protocol.replace(':', '')}://${url}`;
    }
    window.open(url, '_blank');
    window.focus();
  }
  //UI
  render() {
    return (
      <>
        <AlertController {...Utils.propagateRef(this, 'alertController')} />
        {this.idm.isLogged() && !this.isAuthenticating ? this.renderAuthorizedView() : this.renderUnauthorizedView()}
      </>
    );
  }

  /* helpers */
  async _getIDMToken() {
    if (this.idm.session.authorization) {
      const token = await this.idm.session.getToken(true);
      return `Bearer ${token}`;
    }
    return null;
  }

  /* private */
  async _identifyUser() {
    if (process.env.REACT_APP_OFFLINE) return;
    //Identify
    const userObj = await this.idm.session.data.getUserObject();
    const userID = this.idm.session.authorization.getUserID() || userObj.email;
    // Identify the user with LogRocket
    LogRocket.identify(userID, {
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      email: userObj.email,
      application: 'BCCSA CCPO',
      userType: this.isAdmin() ? 'Admin' : this.isAdvisor() ? 'Advisor' : 'User',
    });
  }
  async _loadCache() {
    //Check if could load cache properly, if not we can't proceed :/
    //Since the user has already logged it worth trying 2/3 times so
    //we dont loose user retetion :p
    let retries = 0;
    const maxAttempts = 3;
    while (retries < maxAttempts) {
      if (await this.sharedCache().loadCache()) break;
      await Utils.shortRandSleep();
      retries++;
    }
    //Check if reach max attempts
    if (maxAttempts == retries) {
      await this.idm.session.clearSession(true); //light logout
      this.sharedCache().clearCache();
      if (this.sessionDidFailLoadHandler) this.sessionDidFailLoadHandler();
    } else {
      //Check for server time
      let timeDiff = Math.abs(Date.now() - this.sharedCache().getTenantConfig().serverTime);
      console.debug('System time diff:', timeDiff);
      if (timeDiff > Globals.API_MaxEpochDiscrepancy) {
        if (this.sessionDidFailLoadHandler)
          this.sessionDidFailLoadHandler(
            "Connection with the server can't be established because there is a discrepancy between your time and the server time! Please, enable automatic system date synchronization."
          );
        await this.idm.session.clearSession(true); //light logout
        this.sharedCache().clearCache();
      } else if (this.sessionDidLoadHandler) this.sessionDidLoadHandler();
    }
  }
}
