import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Button, Form, Input, Icon } from 'antd';
//
// import { IDMGlobals } from '@ikonintegration/idmclient';
import CustomComponent from '../../components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
// resources
import '../../assets/stylesheets/CommonLogin.scss';
//
// import Globals from '../../config/Globals';
import Utils from '../../components/Utils';
import config from '../../config/config';
//
import Logo from '../commonComponents/Logo';
//
const { Content } = Layout;
//
export default class CommonLoginView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
    this.props.app.sessionWillLoadHandler = this.sessionWillLoadHandler;
    this.props.app.sessionDidLoadHandler = this.sessionDidLoadHandler;
    this.props.app.sessionDidFailLoadHandler = this.sessionDidFailLoadHandler;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Sign in - ${config.ApplicationName}`;
  }

  //session delegate -
  sessionWillLoadHandler() {
    if (this._isMounted) this.startLoading();
  }
  sessionDidLoadHandler() {
    if (this._isMounted) this.stopLoading(); //most of the cases it will be umounted already
  }
  sessionDidFailLoadHandler(err) {
    if (!err) err = 'Could not load application information. Please, check your internet connection.';
    this.props.app.alertController.showErrorAlert('Error!', err);
    if (this._isMounted) this.stopLoading();
  }

  // Actions
  async handleSignin(e) {
    if (e) e.preventDefault();
    this.startLoading();
    await this.props.app.idm.authenticator.login(
      this.emailInput.state.value,
      null,
      [config.IDMClientOptions.roles.USER, config.IDMClientOptions.roles.SHARED_MODULES],
      window.location
    );
  }
  handleRegistration(e) {
    e.preventDefault();
    this.props.app.idm.registration.register([
      config.IDMClientOptions.roles.USER,
      config.IDMClientOptions.roles.SHARED_MODULES,
    ]);
  }

  // UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    return (
      <Content className="pageContentFullScreen">
        <CommonLoadingView isLoading={isLoading} />
        <Row type="flex" justify="center" align="middle" className="authorizationContainer">
          <Col span={6} className="authorizationBoxContainer" align="middle" justify="center">
            <div className="authorizationBox">
              <Row>
                <Col align="center">
                  {' '}
                  <Logo />{' '}
                </Col>
              </Row>
              <Row>
                <Col align="center">
                  <h5 className="authorizationBoxTitle">Log in</h5>
                  <h6 className="authorizationBoxDescription">Use your BCCSA Account</h6>
                  <Form className="authorizationForm" onSubmit={this.handleSignin}>
                    <Form.Item>
                      <Input
                        className="authorizationFormInput"
                        placeholder="Username"
                        {...Utils.propagateRef(this, 'emailInput')}
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="space-between">
                <Col align="center">
                  <a className="authorizationCreateAccountLink" onClick={this.handleRegistration} href="">
                    Create account
                  </a>
                </Col>
                <Col align="center">
                  <Button className="authorizationLoginButton" onClick={this.handleSignin}>
                    Log in
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}
